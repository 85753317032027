<script>
	export let showDonate = true;
	export let sticky = false;
</script>

<div class="donate-box" class:sticky>
	<h2>what is this?</h2>
	<hr />
	<p class="text-block">
		This trivia is a passion project hand written (almost) every week by <a
			href="https://www.southburlingtonfoodshelf.org/"
			target="_blank">South Burlington Food Shelf's</a
		> director, Pedro.
	</p>
	<a href="/about" class="learn-more">learn more</a>
	{#if showDonate}
		<a href="/donate" target="_blank" class="btn donate prominate">support the foodshelf</a>
	{/if}
</div>

<style>
	.sticky {
		position: sticky;
		top: 5em;
	}
	@media only screen and (max-device-width: 991px) {
		.sticky {
			position: static;
			top: 0;
		}
	}
	.donate-box {
		border: 3px solid black;
		padding: 1em 1em;
		max-width: 18em;
	}
	.donate-box > h2 {
		margin-top: 0;
		margin-bottom: 0;
		font-variation-settings: 'wght' 600;
	}
	.donate-box > hr {
		margin-bottom: 1em;
	}
	.text-block > a {
		font-variation-settings: 'wght' 400;
	}
	.learn-more {
		display: block;
		margin-top: 1em;
	}
	.donate {
		margin-top: 2em;
		display: block;
		text-align: center;
	}
</style>
